import clsx from 'clsx';
import React, { useContext } from 'react';
import { CARD_SIZE } from '../constants/constants';
import { StateContext } from '../store/store';
import Card from './Card';

const CardsPlaceholder = ({ placeholder }) => (
	<div
		className={
			'flex justify-center items-center text-white w-auto shadow-placeholder rounded-2'
		}
		style={{ height: CARD_SIZE.height }}
	>
		<div>Empty {placeholder}</div>
	</div>
);

const CardsContent = ({ className = {}, cardsOrigin, children }) => {
	return (
		<div
			className={className}
			style={{ gridArea: cardsOrigin === 'hand' ? 'hand' : 'board' }}
		>
			{children}
		</div>
	);
};

const Cards = ({ className, secretView, playerPositionIndex, cardsOrigin }) => {
	const [{ G }] = useContext(StateContext);
	const { players } = G;
	const cardsArray = players[playerPositionIndex][cardsOrigin];
	const isCardsArrayEmpty = cardsArray.length === 0;

	// If Hand is empty or both Board cards (known and unknown) are empty
	// render Empty placeholder instead of cards
	if (isCardsArrayEmpty && cardsOrigin !== 'knownCards') {
		return (
			<CardsContent cardsOrigin={cardsOrigin}>
				<CardsPlaceholder
					placeholder={cardsOrigin === 'hand' ? 'Hand' : 'Board'}
				/>
			</CardsContent>
		);
	}

	return (
		<CardsContent className={className} cardsOrigin={cardsOrigin}>
			{cardsArray.map((cardId) => (
				<Card
					key={cardId}
					cardId={cardId}
					playerPositionIndex={playerPositionIndex}
					secretView={secretView}
					cardOrigin={cardsOrigin}
				/>
			))}
		</CardsContent>
	);
};

export const HandCards = ({ playerID, playerPositionIndex }) => {
	const [{ G }] = useContext(StateContext);
	const { players } = G;

	const cardsOrigin = 'hand';
	const cardsArray = players[playerPositionIndex][cardsOrigin];

	return (
		<Cards
			className={clsx(
				'grid pr-35',
				(cardsArray.length > 1 && 'grid-cols-cards grid-rows-cards') ||
					'place-self-center pr-unset'
			)}
			playerPositionIndex={playerPositionIndex}
			cardsOrigin={cardsOrigin}
			secretView={+playerID !== +playerPositionIndex}
		/>
	);
};

export const BoardCards = ({ playerPositionIndex }) => (
	<Cards
		className={'flex flex-row justify-around'}
		playerPositionIndex={playerPositionIndex}
		cardsOrigin={'knownCards'}
		secretView={false}
	/>
);

export const UnknownCards = ({ playerPositionIndex }) => (
	<Cards
		className={'flex flex-row justify-around'}
		playerPositionIndex={playerPositionIndex}
		cardsOrigin={'unknownCards'}
		secretView={true}
	/>
);
