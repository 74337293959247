import { makeStyles } from '@material-ui/core';
import React, { useContext } from 'react';
import { StateContext } from '../store/store';
import DeckPile from './DeckPile';

const suits = new Map([
	['H', '♥'],
	['S', '♠'],
	['D', '♦'],
	['C', '♣']
]);

const useStyles = makeStyles({
	root: {
		display: 'flex',
		flexDirection: 'column',
		gridArea: 'board',
		justifyContent: 'space-evenly'
	},
	controls: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexWrap: 'wrap'
	}
});

const BoardCenter = () => {
	const [state] = useContext(StateContext);
	const { G, ctx, matchData } = state;
	const classes = useStyles();

	return (
		<>
			<DeckPile />
			<div className={classes.controls} style={{ gridArea: 'logger' }}>
				{(ctx.gameover && (
					<p className={'text-white'}>
						Game over!
						{matchData?.length > 0
							? ` ${matchData[G.finishedPlayers.indexOf(false)].name} `
							: ` Player ${G.finishedPlayers.indexOf(false)} `}
						is S**THEAD!
					</p>
				)) || (
					<>
						<p className={'text-white'}>
							Last played:{' '}
							{G.lastPlayedCards.map(
								(card) =>
									(card.charAt(0) !== 'T' ? card.charAt(0) : '10') +
									`${suits.get(card.charAt(1))} `
							)}
						</p>
					</>
				)}
			</div>
		</>
	);
};

export default BoardCenter;
