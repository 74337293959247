import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useContext, useEffect } from 'react';
import useSound from 'use-sound';
import turnStart from '../sounds/your_turn01.wav';
import { StateContext } from '../store/store';
import { BoardCards, HandCards, UnknownCards } from './Cards';

const useStylesOuter = (props) => {
	const useStylesInner = makeStyles((theme) => ({
		root: {
			display: 'grid',
			gridTemplate: `
				'. label label .'
				'board board board board'
				'hand hand hand hand'`,
			placeSelf: ({ gridArea }) =>
				gridArea === 'hand-b1' || gridArea === 'hand-t3'
					? 'end'
					: gridArea === 'hand-b3' || gridArea === 'hand-t1'
					? 'start'
					: 'center',
			borderRadius: 2,
			boxShadow: ({ ctx, playerPositionIndex, playerID }) =>
				ctx.activePlayers &&
				Object.keys(ctx.activePlayers).includes(playerPositionIndex.toString())
					? '0 0 0 6px rgba(255, 230, 109, 1)'
					: +playerID === +playerPositionIndex
					? '0 0 0 6px #f8f9fa'
					: +playerPositionIndex < ctx.numPlayers
					? '0 0 0 2px #f8f9fa'
					: '0 0 0 1px #cfcfcf',
			padding: '5px 20px',
			height: 190,
			width: 280,
			gridArea: ({ gridArea }) => gridArea
		},
		placeholder: {
			gridArea: 'label'
		}
	}));

	return useStylesInner(props);
};

const BoardPlayer = ({ playerPositionIndex, gridArea }) => {
	const [{ ctx, matchData, playerID }] = useContext(StateContext);
	const classes = useStylesOuter({
		gridArea,
		playerID,
		playerPositionIndex,
		ctx
	});

	const [play] = useSound(turnStart);
	const shouldAnimatePulse =
		ctx.activePlayers &&
		Object.keys(ctx.activePlayers).includes(playerPositionIndex.toString());

	useEffect(() => {
		if (
			ctx.activePlayers &&
			Object.keys(ctx.activePlayers).includes(playerPositionIndex.toString()) &&
			+playerID === +playerPositionIndex &&
			ctx.phase !== 'swapping'
		) {
			play();
		}
	}, [ctx, play]);

	return (
		<div
			className={clsx(
				classes.root,
				shouldAnimatePulse && 'animate-pulse-player'
			)}
		>
			{(ctx.numPlayers >= +playerPositionIndex + 1 && (
				<>
					<p
						className={clsx(
							classes.placeholder,
							'justify-self-center text-white'
						)}
					>
						{(matchData?.length > 0 && matchData[+playerPositionIndex].name) ||
							`Player ${playerPositionIndex + 1}`}
					</p>
					<UnknownCards playerPositionIndex={playerPositionIndex} />
					<BoardCards playerPositionIndex={playerPositionIndex} />
					<HandCards
						playerPositionIndex={playerPositionIndex}
						playerID={playerID}
					/>
				</>
			)) || (
				<p
					className={clsx(classes.placeholder, 'justify-self-center text-grey')}
				>
					Player {playerPositionIndex + 1}
				</p>
			)}
		</div>
	);
};

export default BoardPlayer;
