import { INVALID_MOVE } from 'boardgame.io/core';
import {
	CARD_VALUES,
	MAGIC_CARDS,
	DECK,
	HALF_MAGIC_SEVEN
} from '../constants/constants';

const magicMultiplier = 14;

const avgHandValue = (hand) =>
	hand.reduce(
		(acc, curr) =>
			+(isMagic(curr) ? magicMultiplier : CARD_VALUES.indexOf(curr.charAt(0))) +
			acc,
		0
	);

const isGreaterOrEqual = (card, pileTopValue) =>
	CARD_VALUES.indexOf(card.charAt(0)) >= CARD_VALUES.indexOf(pileTopValue);

const isLowerOrEqual = (card, pileTopValue) =>
	CARD_VALUES.indexOf(card.charAt(0)) <= CARD_VALUES.indexOf(pileTopValue);

const isHalfMagicSeven = (card) => HALF_MAGIC_SEVEN.includes(card.charAt(0));

const isMagic = (card) => MAGIC_CARDS.includes(card.charAt(0));

const hasEqualValueAsTopCard = (card, pileTop) =>
	pileTop.length > 0 &&
	pileTop[pileTop.length - 1].charAt(0) === card.charAt(0);

const isPlayable = (G, cardsToPlay) => {
	const card = cardsToPlay[0];

	return (
		isMagic(card) ||
		(isHalfMagicSeven(G.pileTopValue)
			? isLowerOrEqual(card, G.pileTopValue)
			: isGreaterOrEqual(card, G.pileTopValue))
	);
};

const isPlayerFinished = (G, ctx) => {
	const player = G.players[+ctx.currentPlayer];
	return player.hand.length === 0 && player.unknownCards.length === 0;
};

const willBurnPile = (G, cards) => {
	const cardValue = cards[0].charAt(0);
	// 4 or more of the same kind and T card will burn the pile
	if (cards.length > 3 || cardValue === 'T') return true;

	// not enough cards to burn pile
	if (G.secret.pile.length + cards.length < 4) return false;

	const requieredPileCards = Math.abs(cards.length - 4);

	// using negative index to get items from the end of the array
	const pileCards = G.secret.pile.slice(-requieredPileCards);

	return pileCards.every((pileCard) => pileCard.charAt(0) === cardValue);
};

export const sortCards = (cardsArr) => {
	return cardsArr.sort(
		(a, b) =>
			CARD_VALUES.indexOf(a.charAt(0)) - CARD_VALUES.indexOf(b.charAt(0))
	);
};

const insertCard = (cardsArr, card) => {
	return sortCards([...cardsArr, card]);
};

const removeCards = (cardsArr, cardsToRemove) => {
	cardsToRemove.forEach((card) => cardsArr.splice(cardsArr.indexOf(card), 1));
	return sortCards(cardsArr);
};

const DrawCards = (G, ctx) => {
	while (
		G.secret.deck.length > 0 &&
		G.players[ctx.currentPlayer].hand.length < 3
	) {
		let card = G.secret.deck.pop();
		G.players[ctx.currentPlayer].hand = insertCard(
			G.players[ctx.currentPlayer].hand,
			card
		);
	}
};

const skipPlayers = (G, ctx, skipPlayersCount) => {
	const nextPlayer = (prevPlayerIndex, skipCount) => {
		if (skipCount > 0) {
			return nextPlayer(turnOrderNext(G, ctx, prevPlayerIndex), skipCount - 1);
		}
		return prevPlayerIndex;
	};
	return nextPlayer(ctx.playOrderPos, skipPlayersCount);
};

// set pile and cards state corresponding to played card
const processPlayCards = (
	G,
	ctx,
	cardsPlayed,
	cardsOrigin,
	burnPile = false
) => {
	const card = cardsPlayed[0];
	const player = G.players[ctx.currentPlayer];
	G.lastPlayedCards = cardsPlayed;
	G.pileTop = burnPile
		? []
		: (isMagic(card) && card.charAt(0) !== '2') ||
		  hasEqualValueAsTopCard(card, G.pileTop)
		? [...G.pileTop, ...cardsPlayed]
		: [...cardsPlayed];

	if (burnPile) {
		G.secret.pile = [];
		G.pileTopValue = '2';
	} else {
		if (!isMagic(card) || card.charAt(0) === '2') {
			G.pileTopValue = card.charAt(0);
		}

		if (card.charAt(0) === 'J') {
			G.turnOrder *= -1;
		}

		G.secret.pile.push(...cardsPlayed);
	}

	if (cardsOrigin !== 'mixed') {
		player[cardsOrigin] = removeCards(player[cardsOrigin], cardsPlayed);
	} else {
		const board = player.knownCards;
		const hand = player.hand;
		const boardCardsPlayed = cardsPlayed.filter((card) => board.includes(card));
		const handCardsPlayed = cardsPlayed.filter((card) => hand.includes(card));
		player.hand = removeCards(player.hand, handCardsPlayed);
		player.knownCards = removeCards(player.knownCards, boardCardsPlayed);
	}
};

const DrawThenEnd = (G, ctx, skipPlayersCount = 0) => {
	DrawCards(G, ctx);

	if (skipPlayersCount > 0) {
		ctx.events.endTurn({
			next: skipPlayers(G, ctx, skipPlayersCount).toString()
		});
	} else {
		ctx.events.endTurn();
	}
};

const PlayCards = (G, ctx, cardsToPlay, cardsOrigin) => {
	if (isPlayable(G, cardsToPlay)) {
		const burnPile = willBurnPile(G, cardsToPlay);
		const cardsPhase = ctx.phase === 'playingWithCards';
		const shouldSkipAnyNumberOfPlayers =
			cardsToPlay[0].charAt(0) === `J` && cardsToPlay.length > 1;

		processPlayCards(G, ctx, cardsToPlay, cardsOrigin, burnPile);

		// if about to burn pile and can play more cards
		if (burnPile && !isPlayerFinished(G, ctx)) {
			if (cardsPhase) {
				// if more cards in deck available
				ctx.events.setStage('playOrDraw');
			} else {
				// play another cards
				ctx.events.setStage('play');
			}
		} else if (shouldSkipAnyNumberOfPlayers) {
			DrawThenEnd(G, ctx, cardsToPlay.length);
		} else {
			// casual cards played
			DrawThenEnd(G, ctx);
		}
	} else return INVALID_MOVE;
};

const turnOrderNext = (G, ctx, previousPlayer = undefined) => {
	const getNextPlayerIndex = (prevPlayerIndex) =>
		(prevPlayerIndex + G.turnOrder + ctx.numPlayers) % ctx.numPlayers;

	let nextPlayerIndex = getNextPlayerIndex(
		previousPlayer !== undefined ? previousPlayer : ctx.playOrderPos
	);

	// skip finished player bcs endIf check doesn't trigger
	while (G.finishedPlayers[nextPlayerIndex]) {
		nextPlayerIndex = getNextPlayerIndex(nextPlayerIndex);
	}

	return nextPlayerIndex;
};

const getStartingPlayer = (players) => {
	// parse index from string to number
	const playerIndexes = Object.keys(players).map((playerIndex) => +playerIndex);

	// start from the '3' card value
	for (let i = 1; i < CARD_VALUES.length; i++) {
		// filter out players that haven't got this card in hand
		let filteredPlayerIndexes = playerIndexes.filter((playerIndex) =>
			players[playerIndex].hand.some(
				(handCard) => handCard.charAt(0) === CARD_VALUES[i]
			)
		);

		if (filteredPlayerIndexes.length > 0) {
			// if more than 2 players with the same card value
			// reduce player array to player with lowest avg hand value and player index
			return filteredPlayerIndexes.length === 1
				? filteredPlayerIndexes[0]
				: filteredPlayerIndexes.reduce(
						(previousObject, currentPlayerIndex) => {
							const currentObject = {
								playerIndex: currentPlayerIndex,
								handValue: avgHandValue(players[currentPlayerIndex].hand)
							};
							return currentObject.handValue < previousObject.handValue
								? currentObject
								: previousObject;
						},
						{
							playerIndex: filteredPlayerIndexes[0],
							handValue: avgHandValue(players[filteredPlayerIndexes[0]].hand)
						}
				  ).playerIndex;
		}

		// reset player indexes
		filteredPlayerIndexes = [...playerIndexes];
	}
};

export const DrawThenPlay = (G, ctx) => {
	DrawCards(G, ctx);
	ctx.events.setActivePlayers({ currentPlayer: 'play' });
};

export const PlayUnknownCards = (G, ctx, cardsToPlay = []) => {
	if (ctx.phase === 'playingWOCards') {
		const player = G.players[ctx.currentPlayer];

		if (player.knownCards.length === 0 && player.hand.length === 0) {
			const unknownCards = player.unknownCards;
			const unknowCardsToPlay = cardsToPlay.filter((card) =>
				unknownCards.includes(card)
			);
			if (!isPlayable(G, cardsToPlay)) {
				// take unknown card to hand and take pile
				player.hand.push(...cardsToPlay);
				G.players[ctx.currentPlayer].unknownCards = removeCards(
					G.players[ctx.currentPlayer].unknownCards,
					cardsToPlay
				);
				TakePile(G, ctx);
			} else {
				return PlayCards(G, ctx, unknowCardsToPlay, 'unknownCards');
			}
		} else return INVALID_MOVE;
	} else return INVALID_MOVE;
};

export const PlayBoardCard = (G, ctx, cardsToPlay = []) => {
	if (ctx.phase === 'playingWOCards') {
		const player = G.players[ctx.currentPlayer];
		const hand = G.players[ctx.currentPlayer].hand || [];

		if (hand.every((card) => cardsToPlay.includes(card))) {
			let cardsOrigin = 'knownCards';
			const board = player.knownCards;
			/*const boardCardsToPlay = cardsToPlay.filter((card) =>
				board.includes(card)
			);*/

			if (!cardsToPlay.every((card) => board.includes(card))) {
				cardsOrigin = 'mixed';
			}
			return PlayCards(G, ctx, cardsToPlay, cardsOrigin);
		} else return INVALID_MOVE;
	} else return INVALID_MOVE;
};

export const PlayHandCards = (G, ctx, cardsToPlay = []) => {
	let cardsOrigin = 'hand';
	const hand = G.players[ctx.currentPlayer].hand;
	//const handCardsToPlay = cardsToPlay.filter((card) => hand.includes(card));

	if (
		!cardsToPlay.every((card) => hand.includes(card)) &&
		ctx.phase === 'playingWOCards'
	) {
		cardsOrigin = 'mixed';
	}

	if (cardsToPlay.length > 0) {
		return PlayCards(G, ctx, cardsToPlay, cardsOrigin);
	} else return INVALID_MOVE;
};

export const hasPlayable = (G, ctx) => {
	const player = G.players[ctx.currentPlayer];

	return player.hand.length > 0
		? player.hand.some((handCard) => isPlayable(G, [handCard]))
		: player.knownCards.length > 0
		? player.knownCards.some((knownCard) => isPlayable(G, [knownCard]))
		: player.unknownCards.some((unknownCard) => isPlayable(G, [unknownCard]));
};

export const TakePile = (G, ctx) => {
	if (!hasPlayable(G, ctx)) {
		const player = G.players[ctx.currentPlayer];
		if (player.hand.length === 0 && player.knownCards.length > 0) {
			const lowestCard = player.knownCards.reduce(
				(acc, current) =>
					isLowerOrEqual(current, acc.charAt(0)) ? current : acc,
				player.knownCards[0]
			);

			player.hand.push(lowestCard);
			player.knownCards = removeCards(player.knownCards, [lowestCard]);
		}
		G.players[ctx.currentPlayer].hand.push(...G.secret.pile);
		sortCards(G.players[ctx.currentPlayer].hand);
		G.secret.pile = [];
		G.pileTop = [];
		G.pileTopValue = '2';
		ctx.events.endTurn();
	} else return INVALID_MOVE;
};

export const SwapCards = (G, ctx, card1ID, card2ID) => {
	let hand = G.players[ctx.playerID].hand;
	let board = G.players[ctx.playerID].knownCards;

	const swap = (handCard, knownCard) => {
		let tmp;
		tmp = hand[hand.indexOf(handCard)];
		hand[hand.indexOf(handCard)] = board[board.indexOf(knownCard)];
		board[board.indexOf(knownCard)] = tmp;
	};

	if (hand.includes(card2ID) && board.includes(card1ID)) {
		swap(card2ID, card1ID);
	} else if (hand.includes(card1ID) && board.includes(card2ID)) {
		swap(card1ID, card2ID);
	}
};

export const InitPlayers = (ctx, objBody) =>
	[...Array(ctx.numPlayers).keys()].reduce((acc, current) => {
		acc[current] = objBody;
		return acc;
	}, {});

export const GenerateDeck = (ctx, count) => {
	let deck = [];

	const randomShuffle = (dck) => {
		const max = dck.length;
		const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

		for (let i = 0; i < 1000; i++) {
			const splitDeck1 = dck.splice(0, getRandomInt(max));
			dck.splice(dck.length, 0, ...splitDeck1);
		}

		return dck;
	};

	// add index to enable different card id from each deck
	for (let i = 0; i < count; i++) {
		deck = [...deck, ...DECK.map((card) => card + i)];
	}
	const result = randomShuffle(ctx.random.Shuffle(deck));
	return result;
};

export const playingWithCardsTurnOrder = {
	order: {
		first: (G) => getStartingPlayer(G.players),

		next: turnOrderNext
	}
};

export const playingWOCardsTurnOrder = {
	order: {
		// check if prev player should continue playing
		// (burnt pile, drawn cards and should continue with play stage)
		first: (G, ctx) =>
			G.prevPhasePlayerStage === 'playOrDraw'
				? ctx.playOrderPos
				: turnOrderNext(G, ctx),

		next: turnOrderNext
	}
};

export const canPlay = (G, ctx) => {
	const player = G.players[ctx.currentPlayer];
	if (
		hasPlayable(G, ctx) ||
		(player.hand.length === 0 && player.knownCards.length === 0)
	) {
		ctx.events.setActivePlayers({ currentPlayer: 'play' });
	} else {
		ctx.events.setActivePlayers({ currentPlayer: 'take' });
	}
};

export const updateFinishedPlayers = (G, ctx) => {
	G.finishedPlayers[+ctx.currentPlayer] = isPlayerFinished(G, ctx);
	//if (hasEmptyCardsSlots) ctx.events.endTurn();

	//return hasEmptyCardsSlots;
};
