import React from 'react';
import LobbyLoginForm from './LobbyLoginForm';
import LobbyListOfGames from './LobbyListOfGames';
import LobbyGameComponent from './LobbyGameComponent';

const LobbyPhases = {
	ENTER: 'enter',
	PLAY: 'play',
	LIST: 'list'
};

const LobbyRenderer = ({
	errorMsg,
	gameComponents,
	matches,
	phase,
	playerName,
	runningMatch,
	handleEnterLobby,
	handleExitLobby,
	handleCreateMatch,
	handleJoinMatch,
	handleLeaveMatch,
	handleExitMatch,
	handleRefreshMatches,
	handleStartMatch
}) => {
	return (
		<>
			{phase === LobbyPhases.ENTER && (
				<LobbyLoginForm
					key={playerName}
					playerName={playerName}
					handleOnEnter={handleEnterLobby}
				/>
			)}
			{phase === LobbyPhases.LIST && (
				<LobbyListOfGames
					playerName={playerName}
					gameComponents={gameComponents}
					matches={matches}
					handleCreateMatch={handleCreateMatch}
					handleJoinMatch={handleJoinMatch}
					handleLeaveMatch={handleLeaveMatch}
					handleStartMatch={handleStartMatch}
					handleExitLobby={handleExitLobby}
					errorMsg={errorMsg}
				/>
			)}
			{phase === LobbyPhases.PLAY && (
				<LobbyGameComponent
					runningMatch={runningMatch}
					handleExitMatch={handleExitMatch}
				/>
			)}
		</>
	);
};

export default LobbyRenderer;
