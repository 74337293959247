import cardHearts2 from './assets/cardHearts2.png';
import cardHearts3 from './assets/cardHearts3.png';
import cardHearts4 from './assets/cardHearts4.png';
import cardHearts5 from './assets/cardHearts5.png';
import cardHearts6 from './assets/cardHearts6.png';
import cardHearts7 from './assets/cardHearts7.png';
import cardHearts8 from './assets/cardHearts8.png';
import cardHearts9 from './assets/cardHearts9.png';
import cardHearts10 from './assets/cardHearts10.png';
import cardHeartsJ from './assets/cardHeartsJ.png';
import cardHeartsQ from './assets/cardHeartsQ.png';
import cardHeartsK from './assets/cardHeartsK.png';
import cardHeartsA from './assets/cardHeartsA.png';
import cardDiamonds2 from './assets/cardDiamonds2.png';
import cardDiamonds3 from './assets/cardDiamonds3.png';
import cardDiamonds4 from './assets/cardDiamonds4.png';
import cardDiamonds5 from './assets/cardDiamonds5.png';
import cardDiamonds6 from './assets/cardDiamonds6.png';
import cardDiamonds7 from './assets/cardDiamonds7.png';
import cardDiamonds8 from './assets/cardDiamonds8.png';
import cardDiamonds9 from './assets/cardDiamonds9.png';
import cardDiamonds10 from './assets/cardDiamonds10.png';
import cardDiamondsJ from './assets/cardDiamondsJ.png';
import cardDiamondsQ from './assets/cardDiamondsQ.png';
import cardDiamondsK from './assets/cardDiamondsK.png';
import cardDiamondsA from './assets/cardDiamondsA.png';
import cardClubs2 from './assets/cardClubs2.png';
import cardClubs3 from './assets/cardClubs3.png';
import cardClubs4 from './assets/cardClubs4.png';
import cardClubs5 from './assets/cardClubs5.png';
import cardClubs6 from './assets/cardClubs6.png';
import cardClubs7 from './assets/cardClubs7.png';
import cardClubs8 from './assets/cardClubs8.png';
import cardClubs9 from './assets/cardClubs9.png';
import cardClubs10 from './assets/cardClubs10.png';
import cardClubsJ from './assets/cardClubsJ.png';
import cardClubsQ from './assets/cardClubsQ.png';
import cardClubsK from './assets/cardClubsK.png';
import cardClubsA from './assets/cardClubsA.png';
import cardSpades2 from './assets/cardSpades2.png';
import cardSpades3 from './assets/cardSpades3.png';
import cardSpades4 from './assets/cardSpades4.png';
import cardSpades5 from './assets/cardSpades5.png';
import cardSpades6 from './assets/cardSpades6.png';
import cardSpades7 from './assets/cardSpades7.png';
import cardSpades8 from './assets/cardSpades8.png';
import cardSpades9 from './assets/cardSpades9.png';
import cardSpades10 from './assets/cardSpades10.png';
import cardSpadesJ from './assets/cardSpadesJ.png';
import cardSpadesQ from './assets/cardSpadesQ.png';
import cardSpadesK from './assets/cardSpadesK.png';
import cardSpadesA from './assets/cardSpadesA.png';
import cardBackBlue from './assets/cardBack_blue4.png';
import cardBackRed from './assets/cardBack_red4.png';
import cw from './assets/cw.png';
import ccw from './assets/ccw.png';
import { DECK } from '../constants/constants';

const imageIDs = [
	{ id: 'cardHearts2', src: cardHearts2, alt: '2 of Hearts' },
	{ id: 'cardHearts3', src: cardHearts3, alt: '3 of Hearts' },
	{ id: 'cardHearts4', src: cardHearts4, alt: '4 of Hearts' },
	{ id: 'cardHearts5', src: cardHearts5, alt: '5 of Hearts' },
	{ id: 'cardHearts6', src: cardHearts6, alt: '6 of Hearts' },
	{ id: 'cardHearts7', src: cardHearts7, alt: '7 of Hearts' },
	{ id: 'cardHearts8', src: cardHearts8, alt: '8 of Hearts' },
	{ id: 'cardHearts9', src: cardHearts9, alt: '9 of Hearts' },
	{ id: 'cardHeartsT', src: cardHearts10, alt: '10 of Hearts' },
	{ id: 'cardHeartsJ', src: cardHeartsJ, alt: 'J of Hearts' },
	{ id: 'cardHeartsQ', src: cardHeartsQ, alt: 'Q of Hearts' },
	{ id: 'cardHeartsK', src: cardHeartsK, alt: 'K of Hearts' },
	{ id: 'cardHeartsA', src: cardHeartsA, alt: 'A of Hearts' },
	{ id: 'cardDiamonds2', src: cardDiamonds2, alt: '2 of Diamonds' },
	{ id: 'cardDiamonds3', src: cardDiamonds3, alt: '3 of Diamonds' },
	{ id: 'cardDiamonds4', src: cardDiamonds4, alt: '4 of Diamonds' },
	{ id: 'cardDiamonds5', src: cardDiamonds5, alt: '5 of Diamonds' },
	{ id: 'cardDiamonds6', src: cardDiamonds6, alt: '6 of Diamonds' },
	{ id: 'cardDiamonds7', src: cardDiamonds7, alt: '7 of Diamonds' },
	{ id: 'cardDiamonds8', src: cardDiamonds8, alt: '8 of Diamonds' },
	{ id: 'cardDiamonds9', src: cardDiamonds9, alt: '9 of Diamonds' },
	{ id: 'cardDiamondsT', src: cardDiamonds10, alt: '10 of Diamonds' },
	{ id: 'cardDiamondsJ', src: cardDiamondsJ, alt: 'J of Diamonds' },
	{ id: 'cardDiamondsQ', src: cardDiamondsQ, alt: 'Q of Diamonds' },
	{ id: 'cardDiamondsK', src: cardDiamondsK, alt: 'K of Diamonds' },
	{ id: 'cardDiamondsA', src: cardDiamondsA, alt: 'A of Diamonds' },
	{ id: 'cardClubs2', src: cardClubs2, alt: '2 of Clubs' },
	{ id: 'cardClubs3', src: cardClubs3, alt: '3 of Clubs' },
	{ id: 'cardClubs4', src: cardClubs4, alt: '4 of Clubs' },
	{ id: 'cardClubs5', src: cardClubs5, alt: '5 of Clubs' },
	{ id: 'cardClubs6', src: cardClubs6, alt: '6 of Clubs' },
	{ id: 'cardClubs7', src: cardClubs7, alt: '7 of Clubs' },
	{ id: 'cardClubs8', src: cardClubs8, alt: '8 of Clubs' },
	{ id: 'cardClubs9', src: cardClubs9, alt: '9 of Clubs' },
	{ id: 'cardClubsT', src: cardClubs10, alt: '10 of Clubs' },
	{ id: 'cardClubsJ', src: cardClubsJ, alt: 'J of Clubs' },
	{ id: 'cardClubsQ', src: cardClubsQ, alt: 'Q of Clubs' },
	{ id: 'cardClubsK', src: cardClubsK, alt: 'K of Clubs' },
	{ id: 'cardClubsA', src: cardClubsA, alt: 'A of Clubs' },
	{ id: 'cardSpades2', src: cardSpades2, alt: '2 of Spades' },
	{ id: 'cardSpades3', src: cardSpades3, alt: '3 of Spades' },
	{ id: 'cardSpades4', src: cardSpades4, alt: '4 of Spades' },
	{ id: 'cardSpades5', src: cardSpades5, alt: '5 of Spades' },
	{ id: 'cardSpades6', src: cardSpades6, alt: '6 of Spades' },
	{ id: 'cardSpades7', src: cardSpades7, alt: '7 of Spades' },
	{ id: 'cardSpades8', src: cardSpades8, alt: '8 of Spades' },
	{ id: 'cardSpades9', src: cardSpades9, alt: '9 of Spades' },
	{ id: 'cardSpadesT', src: cardSpades10, alt: '10 of Spades' },
	{ id: 'cardSpadesJ', src: cardSpadesJ, alt: 'J of Spades' },
	{ id: 'cardSpadesQ', src: cardSpadesQ, alt: 'Q of Spades' },
	{ id: 'cardSpadesK', src: cardSpadesK, alt: 'K of Spades' },
	{ id: 'cardSpadesA', src: cardSpadesA, alt: 'A of Spades' },
	{ id: 'cardBackBlue', src: cardBackBlue, alt: 'Card back' }
];

//Too lazy to refactor names from different project -> lets dynamicaly create Map!
const images = new Map();

DECK.forEach((value, index) => {
	images.set(value, imageIDs[index]);
});
//images.set('back', imageIDs[imageIDs.length - 1]);
images.set('back', { id: 'cardBackRed', src: cardBackRed, alt: 'Card back' });
images.set('cw', { id: 'cw', src: cw, alt: 'clockwise turn order' });
images.set('ccw', { id: 'ccw', src: ccw, alt: 'counterclockwise turn order' });

export default images;
