import clsx from 'clsx';
import React, { useContext } from 'react';
import { CARD_SIZE } from '../constants/constants';
import images from '../images/images';
import { unselectCards } from '../store/actions';
import { StateContext } from '../store/store';
import CardImage from './CardImage';

const CountLabel = ({ className = {}, count }) => (
	<div className={clsx(className)}>
		<p className={'text-center text-white'}>{count}</p>
	</div>
);

const TurnOrderImage = ({ className = {}, style = {}, image, rotation }) => (
	<img
		className={clsx(
			className,
			'max-w-55 place-self-center',
			rotation === 'cw' ? 'animate-rotate-cw' : 'animate-rotate-ccw'
		)}
		style={style}
		src={image.src}
		alt={image.alt}
	/>
);

const DeckPilePlaceholder = ({ placeholder }) => (
	<div
		className={
			'flex justify-center items-center text-white shadow-placeholder rounded-2'
		}
		style={{ width: CARD_SIZE.width, height: CARD_SIZE.height }}
	>
		<p>{placeholder}</p>
	</div>
);

const CardStack = ({ className = {}, style = {}, children }) => {
	return (
		<div className={className} style={style}>
			{children}
		</div>
	);
};

const cardBackImage = images.get('back');

const DeckPile = () => {
	const [state, dispatch] = useContext(StateContext);
	const { G, moves } = state;

	const takePile = () => {
		dispatch(unselectCards());
		moves.TakePile();
	};

	const image = images.get(G.turnOrder > 0 ? 'cw' : 'ccw');

	return (
		<>
			<CardStack className={''} style={{ gridArea: 'deck' }}>
				<CountLabel count={G.secret.deck.length} />
				{(G.secret.deck.length > 0 && (
					<CardImage
						cardImage={cardBackImage}
						cardId={'back'}
						onClick={() => moves.DrawThenPlay()}
					/>
				)) || <DeckPilePlaceholder placeholder={'Deck'} />}
			</CardStack>

			<TurnOrderImage
				style={{ gridArea: 'spinner' }}
				image={image}
				rotation={G.turnOrder > 0 ? 'cw' : 'ccw'}
			/>

			<CardStack className={''} style={{ gridArea: 'pile' }}>
				<CountLabel count={G.secret.pile.length} />
				<div
					className={clsx(
						'',
						G.pileTop.length > 0 && 'grid grid-cols-cards auto-rows-cards'
					)}
				>
					{(G.pileTop.length > 0 &&
						G.pileTop?.map((card) => (
							<div key={card}>
								<CardImage
									cardImage={images.get(card?.slice(0, 2))}
									cardId={card}
									onClick={takePile}
								/>
							</div>
						))) || <DeckPilePlaceholder placeholder={'Pile'} />}
				</div>
			</CardStack>
		</>
	);
};

export default DeckPile;
