import { ActivePlayers } from 'boardgame.io/core';
import {
	InitPlayers,
	GenerateDeck,
	SwapCards,
	playingWithCardsTurnOrder,
	playingWOCardsTurnOrder,
	PlayHandCards,
	PlayBoardCard,
	PlayUnknownCards,
	DrawThenPlay,
	TakePile,
	canPlay,
	updateFinishedPlayers,
	sortCards
} from './helpers';

const MAX_CARDS = 3;

export const Poohead = {
	name: 'poohead',

	seed: Date.now().toString(),

	setup: (ctx) => ({
		pileTop: [],
		pileTopValue: '2',
		lastPlayedCards: [],
		prevPhasePlayerStage: '',
		animateSelectedCards: [],
		players: InitPlayers(ctx, {
			hand: Array(0),
			knownCards: Array(0),
			unknownCards: Array(0)
		}),
		finishedPlayers: Array(ctx.numPlayers).fill(false),
		turnOrder: 1,
		secret: {
			deck: [],
			pile: []
		}
	}),

	minPlayers: 2,
	maxPlayers: 8,

	turn: {
		order: {
			first: () => 0,

			next: (G, ctx) =>
				(ctx.playOrderPos + G.turnOrder + ctx.numPlayers) % ctx.numPlayers
		}
	},

	phases: {
		dealing: {
			start: true,
			onBegin: (G, ctx) => {
				G.secret.deck = GenerateDeck(ctx, ctx.numPlayers > 3 ? 2 : 1);
				// deal cards to all players
				[...Object.keys(G.players)].forEach((key) => {
					for (let i = 0; i < MAX_CARDS; i++) {
						G.players[key].knownCards.push(G.secret.deck.pop());
						//G.players[key].hand.push(`TH${i}`);
						G.players[key].hand.push(G.secret.deck.pop());
						G.players[key].unknownCards.push(G.secret.deck.pop());
					}

					sortCards(G.players[key].hand);
					sortCards(G.players[key].knownCards);
				});
			},
			endIf: (G) =>
				Object.values(G.players).every(
					(player) => player.hand.length >= MAX_CARDS
				),
			next: 'swapping'
		},
		swapping: {
			turn: {
				activePlayers: ActivePlayers.ALL
			},
			moves: { SwapCards },
			// skip swap
			// endIf: (_) => true,
			next: 'playingWithCards'
		},
		playingWithCards: {
			onBegin: (G, ctx) => {
				Object.values(G.players).forEach((player) => {
					sortCards(player.hand);
					sortCards(player.knownCards);
				});
				// remove deck to skip to next phase
				// G.secret.deck.splice(0, G.secret.deck.length);
				// Object.values(G.players)[0].unknownCards = ["3H0", "TH0", "8H0"];
				// Object.values(G.players)[1].unknownCards = ["3D0", "TD0", "8D0"];
				// remove hand cards
				// Object.values(G.players).map(player => player.hand.splice(0, player.hand.length));
			},
			turn: {
				...playingWithCardsTurnOrder,
				onBegin: canPlay,
				stages: {
					play: {
						moves: { PlayHandCards }
					},
					playOrDraw: {
						moves: { PlayHandCards, DrawThenPlay }
					},
					take: {
						moves: { TakePile }
					}
				},
				onMove: (G, ctx) => (
					(G.prevPhasePlayerStage = ctx.activePlayers[ctx.currentPlayer]), G
				)
			},
			endIf: (G, ctx) => G.secret.deck.length === 0,
			next: 'playingWOCards'
		},

		playingWOCards: {
			turn: {
				...playingWOCardsTurnOrder,
				onBegin: canPlay,
				stages: {
					play: {
						moves: { PlayHandCards, PlayBoardCard, PlayUnknownCards }
					},
					take: {
						moves: { TakePile }
					}
				},
				onEnd: updateFinishedPlayers
			}
		}
	},

	endIf: (G, ctx) =>
		/*console.log(G, ctx),*/
		G.finishedPlayers.filter(Boolean).length === ctx.numPlayers - 1
};
