import { Local } from 'boardgame.io/multiplayer';
import { Client, Lobby } from 'boardgame.io/react';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import './App.css';
import AnimationWrapper from './components/AnimationWrapper';
import BoardLayout from './components/BoardLayout';
import LobbyRenderer from './components/Lobby/LobbyRenderer';
import { Poohead } from './server/Poohead';
import { initialState, reducer, StateProvider } from './store/store';

const PROD = process.env.NODE_ENV === 'production';
const LOBBY_VIEW = true;
const NUM_PLAYERS = 4;

const logger = (action) => {
	console.log('Logger: ', action);
};

const PooheadBoard = (props) => {
	return (
		<StateProvider
			initialState={initialState(props)}
			reducer={reducer}
			middlewareFn={!PROD ? logger : undefined}
		>
			<AnimationWrapper {...props}>
				<BoardLayout />
			</AnimationWrapper>
		</StateProvider>
	);
};

PooheadBoard.propTypes = {
	G: PropTypes.object.isRequired,
	ctx: PropTypes.object.isRequired,
	playerID: PropTypes.string.isRequired,
	credentials: PropTypes.string,
	events: PropTypes.object.isRequired,
	matchData: PropTypes.array,
	matchID: PropTypes.string,
	moves: PropTypes.object.isRequired
};

// game engine library
// https://boardgame.io/documentation/
const App = () => {
	const { protocol, hostname, port } = window.location;
	const serverBase = `${protocol}//${hostname}`;
	const lobbyBase = `${protocol}//${hostname}`;

	if (LOBBY_VIEW) {
		return (
			<Lobby
				gameServer={`${serverBase}:${PROD ? port : 8000}`}
				lobbyServer={`${lobbyBase}:${PROD ? port : 8080}`}
				gameComponents={[{ game: Poohead, board: memo(PooheadBoard) }]}
				renderer={LobbyRenderer}
			/>
		);
	} else {
		const DullheadClient = Client({
			game: Poohead,
			numPlayers: NUM_PLAYERS,
			board: PooheadBoard,
			multiplayer: Local(),
			debug: true
		});

		const DullheadClientComponent = (playerID) => (
			<div
				style={{
					backgroundColor: 'rgb(82,183,136)',
					background:
						'radial-gradient(circle, rgba(82,183,136,1) 0%, rgba(45,106,79,1) 100%)'
				}}
			>
				<DullheadClient {...playerID} logger={true} />
			</div>
		);

		const generateDullheadClientComponents = () => {
			const components = [];
			for (let i = 0; i < NUM_PLAYERS; i++) {
				components.push(
					<DullheadClientComponent key={i} playerID={i.toString()} />
				);
			}

			return components;
		};

		return <div>{generateDullheadClientComponents()}</div>;
	}
};

export default App;
