import React, { useState, useEffect } from 'react';
import { Button, Container, Grid } from '@material-ui/core';
import RulesMarkdown from '../../rules.md';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const LobbyGameComponent = ({ runningMatch, handleExitMatch }) => {
	const [markdownFile, setMarkdownFile] = useState();

	useEffect(() => {
		const fetchMarkdown = async () => {
			const response = await fetch(RulesMarkdown);
			const text = await response.text();
			setMarkdownFile(text);
		};

		if (!markdownFile) {
			fetchMarkdown();
		}
	}, [markdownFile]);

	return (
		<>
			<Container maxWidth={false} component="main" style={{ padding: 0 }}>
				<Grid container direction="column">
					<Grid
						item
						style={{
							backgroundColor: 'rgb(82,183,136)',
							background:
								'radial-gradient(circle, rgba(82,183,136,1) 0%, rgba(45,106,79,1) 100%)'
						}}
					>
						{runningMatch && (
							<runningMatch.app
								matchID={runningMatch.matchID}
								playerID={runningMatch.playerID}
								credentials={runningMatch.credentials}
							/>
						)}
					</Grid>
					<Grid item align="center" style={{ padding: '40px 0 20px 0' }}>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleExitMatch}
						>
							Exit match
						</Button>
					</Grid>
				</Grid>
			</Container>
			<Container component="aside" maxWidth="md" style={{ marginBottom: 30 }}>
				<Button></Button>
				<ReactMarkdown plugins={[gfm]}>{markdownFile}</ReactMarkdown>
			</Container>
		</>
	);
};

export default LobbyGameComponent;
