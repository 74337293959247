export const UPDATE_PROPS = 'UPDATE_PROPS';
export const CARD_CLICKED = 'CARD_CLICK';
export const SELECT_CARDS = 'SELECT_CARDS';
export const UNSELECT_CARDS = 'UNSELECT_CARDS';
export const ANIMATE_PLAY_CARD_START = 'ANIMATE_PLAY_CARD_START';
export const ANIMATE_PLAY_CARD_END = 'ANIMATE_PLAY_CARD_END';
export const ANIMATE_TAKE_PILE_START = 'ANIMATE_TAKE_PILE_START';
export const ANIMATE_TAKE_PILE_END = 'ANIMATE_TAKE_PILE_END';

export const updateProps = (props) => {
	return {
		type: UPDATE_PROPS,
		props
	};
};

export const takePileAnimationStart = (pileCardsToAnimate) => {
	// signal to trigger take pile
	return {
		type: ANIMATE_TAKE_PILE_START,
		cardsToAnimate: pileCardsToAnimate
	};
};

export const takePileAnimationEnd = () => {
	return {
		type: ANIMATE_TAKE_PILE_END,
		cardsToAnimate: []
	};
};

export const playCardAnimationStart = (cardsToAnimate) => {
	return { type: ANIMATE_PLAY_CARD_START, cardsToAnimate };
};

export const playCardAnimationEnd = () => {
	return {
		type: ANIMATE_PLAY_CARD_END,
		cardsToAnimate: []
	};
};

export const cardClicked = (cardId, cardsOrigin) => {
	return { type: CARD_CLICKED, cardId, cardsOrigin };
};

export const selectCards = (cards, cardsOrigin) => {
	return { type: SELECT_CARDS, cards, cardsOrigin };
};
export const unselectCards = () => {
	return { type: UNSELECT_CARDS };
};
