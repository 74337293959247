import clsx from 'clsx';
import React, { useContext } from 'react';
import { ANIMATION_DELAY, CARD_SIZE } from '../constants/constants';
import images from '../images/images';
import {
	cardClicked,
	playCardAnimationEnd,
	playCardAnimationStart,
	unselectCards
} from '../store/actions';
import { StateContext } from '../store/store';
import CardImage from './CardImage';

const rotateYStyle = {
	transform: 'translateY(-15px) rotateY(180deg)'
};

const FaceUpCard = ({ cardId, cardFrontImage, animate }) => {
	const cardBackImage = images.get('back');
	return (
		<>
			<CardImage cardId={cardId} cardImage={cardFrontImage} />
			{animate && (
				<CardImage
					cardId={'back'}
					cardImage={cardBackImage}
					className={'absolute inset-0'}
					style={rotateYStyle}
				/>
			)}
		</>
	);
};

const FaceDownCard = ({ cardId, cardFrontImage, animate }) => {
	const cardBackImage = images.get('back');
	return (
		<>
			<CardImage cardId={cardId} cardImage={cardBackImage} />
			{animate && (
				<CardImage
					cardId={cardId}
					cardImage={cardFrontImage}
					className={'absolute inset-0 z-5000'}
					style={{ ...rotateYStyle, width: CARD_SIZE.width }}
				/>
			)}
		</>
	);
};

const Card = ({ cardId, playerPositionIndex, secretView, cardOrigin }) => {
	const [
		{
			ctx,
			moves,
			selectedCards,
			animation,
			playerID,
			animatePlayCard,
			animateTakePile,
			cardsToAnimate
		},
		dispatch
	] = useContext(StateContext);

	const flipCard = (cardId) => {
		dispatch(playCardAnimationStart([cardId]));

		setTimeout(() => {
			dispatch(playCardAnimationEnd());
			dispatch(unselectCards());
		}, ANIMATION_DELAY);
	};

	const cardClickAction = (cardId, cardsOrigin) => () => {
		dispatch(cardClicked(cardId, cardsOrigin));

		if (ctx.phase === 'swapping') {
			if (selectedCards.length > 0) {
				moves.SwapCards(selectedCards[0], cardId);
			}
		}

		if (ctx.gameover && cardsOrigin === 'unknownCards') {
			flipCard(cardId);
		}
	};

	const cardFrontImage = images.get(cardId.slice(0, 2));

	const highlighted = selectedCards?.includes(cardId);
	const playedCards = cardsToAnimate?.includes(cardId);

	const animate =
		(highlighted && animation) || (playedCards && animatePlayCard);

	const onClick =
		+playerID !== +playerPositionIndex
			? null
			: cardClickAction(cardId, cardOrigin);

	// this split to declaration -> define -> return solved
	// the 'images not showing in prod build' bug
	const resultCard = (
		<div
			className={clsx(
				'transition-transform cursor-pointer',
				animate && 'transform -translate-y-30',
				animateTakePile && playedCards && 'animate-appear'
			)}
			style={{
				...(animate && secretView
					? { ...rotateYStyle, width: CARD_SIZE.width }
					: {}),
				transformStyle: 'preserve-3d',
				backfaceVisibility: 'visible'
			}}
			key={cardId}
			onClick={() => onClick?.()}
		>
			{secretView ? (
				<FaceDownCard
					cardId={cardId}
					cardFrontImage={cardFrontImage}
					animate={animate}
				/>
			) : (
				<FaceUpCard
					cardId={cardId}
					cardFrontImage={cardFrontImage}
					animate={animate}
				/>
			)}
		</div>
	);
	return resultCard;
};

export default Card;
