export const CARD_VALUES = [
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'T',
	'J',
	'Q',
	'K',
	'A'
];
export const CARD_SUITS = ['H', 'D', 'C', 'S'];
export const MAGIC_CARDS = ['2', '8', 'T', 'J'];
export const HALF_MAGIC_SEVEN = ['7'];
export const DECK = CARD_SUITS.reduce(
	(acc, suit) => acc.concat(CARD_VALUES.map((value) => value + suit)),
	[]
);
export const CARD_SIZE = {
	width: 56,
	height: 76
}

export const ANIMATION_DELAY = 1500;

const size = {
	mobileS: '320px',
	mobileM: '375px',
	mobileL: '425px',
	tablet: '768px',
	laptop: '1024px',
	laptopL: '1440px',
	desktop: '2560px'
};

export const DEVICE = {
	mobileS: `(min-width: ${size.mobileS})`,
	mobileM: `(min-width: ${size.mobileM})`,
	mobileL: `(min-width: ${size.mobileL})`,
	tablet: `(min-width: ${size.tablet})`,
	laptop: `(min-width: ${size.laptop})`,
	laptopL: `(min-width: ${size.laptopL})`,
	desktop: `(min-width: ${size.desktop})`,
	desktopL: `(min-width: ${size.desktop})`
};
