import { Button, makeStyles } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import { unselectCards } from '../store/actions';
import { StateContext } from '../store/store';
import BoardCenter from './BoardCenter';
import BoardPlayer from './BoardPlayer';

const playersIndexes = [...Array(8).keys()];
/*
 * t3  t2  t1
 *	   __
 * l1 |__|` r1
 *     `  `
 * b1  b2  b3
 *
 * start -> b2 -> b1 -> ... clockwise order
 */
const playersPositionsRaw = [
	'hand-b2',
	'hand-b1',
	'hand-l1',
	'hand-t3',
	'hand-t2',
	'hand-t1',
	'hand-r1',
	'hand-b3'
];

// need this to deal with potential negative index
const playersBoardPositions = [...playersPositionsRaw, ...playersPositionsRaw];

const useStyles = makeStyles({
	root: {
		display: 'grid',
		justifyItems: 'center',
		gridTemplate: `
			'hand-t3 hand-t3 hand-t3 hand-t2 hand-t2 hand-t2 hand-t1 hand-t1 hand-t1'
			'hand-l1 . . deck . pile . . hand-r1'
			'hand-l1 . . deck spinner pile . . hand-r1'
			'hand-l1 . . logger logger logger . . hand-r1'
			'hand-b1 hand-b1 hand-b1 hand-b2 hand-b2 hand-b2 hand-b3 hand-b3 hand-b3'`,
		gridGap: 15,
		padding: '10px 0',
		margin: 'auto',
		minWidth: 900,
		maxWidth: 1400
	}
});

const BoardLayout = () => {
	const [state, dispatch] = useContext(StateContext);
	const { ctx, moves, playerID, events, cardsOrigin, selectedCards } = state;
	const classes = useStyles();
	const [playAgain, setPlayAgain] = useState(false);

	/*
	const [matchId, setMatchId] = useState('');
	const testUrl = true;
	const lobbyClient = new LobbyClient({
		server: testUrl
			? `http://${window.location.hostname}:8080`
			: 'https://dullhead.herokuapp.com/'
	});

	useEffect(() => {
		const playAgain = async () => {
			const { matchID, playerID, credentials } = props;
			try {
				const { nextMatchID } = await lobbyClient.playAgain(
					'poohead',
					matchID,
					{
						playerID,
						credentials
					}
				);

				setMatchId(nextMatchID);
			} catch (error) {
				console.error(error);
			}
		};

		if (matchId) {
		}

		if (props.ctx.gameover && !matchId) {
			//playAgain();
		}
	}, [props]);

	useEffect(() => {
		const joinMatch = async () => {
			const { playerID, credentials, matchData } = props;
			try {
				const { newCredentials } = await lobbyClient.joinMatch(
					'poohead',
					matchId,
					{
						playerID,
						playerName: matchData.find((data) => data.id === +playerID).name
					}
				);
			} catch (error) {
				console.error(error);
			}
		};

		if (matchId) {
		}

		if (playAgain) {
			//joinMatch();
		}
	}, [playAgain]);

	*/

	const endTurnEvent = () => {
		// if all players can swap simultaneously
		// need to check if all other already swapped

		//console.log(ctx)
		if (!ctx.activePlayers || Object.keys(ctx.activePlayers).length === 1) {
			events.endPhase();
		} else {
			events.endStage();
		}

		dispatch(unselectCards());
	};

	const playCardsEvent = () => {
		if (cardsOrigin === 'hand') {
			moves.PlayHandCards(selectedCards);
		} else if (cardsOrigin === 'knownCards') {
			moves.PlayBoardCard(selectedCards);
		} else if (cardsOrigin === 'unknownCards') {
			moves.PlayUnknownCards(selectedCards);
		}

		dispatch(unselectCards());
	};

	const playAgainHandler = () => {
		if (!playAgain) {
			setPlayAgain(true);
		}
	};

	const ControlButton = () => {
		const controlTypePlay =
			ctx.phase === 'playingWithCards' || ctx.phase === 'playingWOCards';
		const controlTypeEndTurn = ctx.phase === 'swapping';

		const onClick = controlTypePlay
			? () => playCardsEvent()
			: controlTypeEndTurn
			? () => endTurnEvent()
			: () => playAgainHandler();
		const value = controlTypePlay
			? 'Play Card'
			: controlTypeEndTurn
			? 'End Turn'
			: 'Play Again';

		return (
			<Button variant="contained" color="default" onClick={onClick}>
				{value}
			</Button>
		);
	};

	return (
		<React.Fragment>
			<div className={classes.root}>
				<BoardCenter />
				{playersIndexes.map((index) => (
					<BoardPlayer
						key={'player_' + index}
						playerPositionIndex={index}
						gridArea={
							playersBoardPositions[
								// adjust player view position to be always in hand-b2 area
								// by shifting others by playerID positions counterclockwise
								// so actual player view (index - playerID) is 0
								index + playersPositionsRaw.length - playerID
							]
						}
					/>
				))}
			</div>
			<div
				style={{ display: 'flex', justifyContent: 'center', padding: '30px 0' }}
			>
				<ControlButton />
			</div>
		</React.Fragment>
	);
};

export default BoardLayout;
