import React, { useContext, useEffect, useRef } from 'react';
import {
	BehaviorSubject,
	delay,
	distinctUntilChanged,
	exhaustMap,
	iif,
	of,
	pairwise,
	tap
} from 'rxjs';
import { ANIMATION_DELAY } from '../constants/constants';
import { hasPlayable } from '../server/helpers';
import {
	playCardAnimationEnd,
	playCardAnimationStart,
	selectCards,
	takePileAnimationEnd,
	takePileAnimationStart,
	unselectCards,
	updateProps
} from '../store/actions';
import { StateContext } from '../store/store';

const AnimationWrapper = (props) => {
	// useRef to not re-initialize the stream so it can persist full lifetime of component
	const properties$ = useRef(new BehaviorSubject(undefined)).current;
	const [, dispatch] = useContext(StateContext);

	/**
	 * Animation and Game State Props Update Hooks
	 */
	useEffect(() => {
		if (props) {
			properties$.next({ ...props, children: undefined });
		}
	}, [props, properties$]);

	useEffect(() => {
		const playCardAnimation = (cardsToAnimate) => (source$) => {
			return source$.pipe(
				tap(() => {
					dispatch(playCardAnimationStart(cardsToAnimate));
				}),
				delay(ANIMATION_DELAY),
				tap(() => {
					dispatch(playCardAnimationEnd());
				})
			);
		};

		const takePileAnimation = (pileCardsToAnimate) => (source$) => {
			return source$.pipe(
				tap(() => {
					dispatch(takePileAnimationStart(pileCardsToAnimate));
				}),
				delay(ANIMATION_DELAY),
				tap(() => {
					dispatch(takePileAnimationEnd());
					dispatch(unselectCards());
				})
			);
		};

		const pipeIf = (predicate, ...pipes) => (source$) => {
			return source$.pipe(
				exhaustMap((value) =>
					iif(() => predicate, of(value).pipe(...pipes), of(value))
				)
			);
		};

		const subscription = properties$
			.pipe(
				distinctUntilChanged(
					(prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)
				),
				pairwise(),
				exhaustMap(([prev, curr]) => {
					const statePlayerHands = Object.values(prev.G.players).map(
						(player) => player.hand
					);

					const propsPlayerHands = Object.values(curr.G.players).map(
						(player) => player.hand
					);

					const pileCardsToAnimate = [];

					if (statePlayerHands.length === propsPlayerHands.length) {
						// check for changes in player hand and animate any newly acquired cards
						statePlayerHands.forEach((stateHand, handIndex) => {
							if (stateHand.length !== propsPlayerHands[handIndex].length) {
								pileCardsToAnimate.push(
									...propsPlayerHands[handIndex].filter(
										(propCard) => !stateHand.includes(propCard)
									)
								);
							}
						});
					}

					const statePlayerUnknowns = Object.values(prev.G.players).map(
						(player) => player.unknownCards
					);
					const propsPlayerUnknowns = Object.values(curr.G.players).map(
						(player) => player.unknownCards
					);
					const cardsUnknownChanged = [];

					if (statePlayerUnknowns.length === propsPlayerUnknowns.length) {
						// check for changes in unknown cards to decide if we need to chain animations
						// (play unknown card, but if it is unplayable, also animate take pile after this one)
						statePlayerUnknowns.forEach((stateUnknown, unknownIndex) => {
							if (
								stateUnknown.length !== propsPlayerUnknowns[unknownIndex].length
							) {
								cardsUnknownChanged.push(
									...stateUnknown.filter(
										(stateCard) =>
											!propsPlayerUnknowns[unknownIndex].includes(stateCard)
									)
								);
							}
						});
					}

					// If last played cards from fresh props are different than in state
					// trigger cards played animation
					const cardsPlayed =
						JSON.stringify(prev.G.lastPlayedCards) !==
						JSON.stringify(curr.G.lastPlayedCards);
					const pileTaken = pileCardsToAnimate.length > 0;
					const unknownPlayedPileTaken =
						pileTaken && cardsUnknownChanged.length > 0;

					const source$ = of(curr);

					return source$.pipe(
						pipeIf(
							unknownPlayedPileTaken,
							playCardAnimation(cardsUnknownChanged)
						),
						pipeIf(
							cardsPlayed && !unknownPlayedPileTaken,
							playCardAnimation(curr.G.lastPlayedCards)
						),
						pipeIf(
							!hasPlayable(curr.G, curr.ctx),
							tap(() => dispatch(unselectCards())),
							tap(() => dispatch(selectCards(curr.G.pileTop, 'pileTop')))
						),
						tap(() => {
							dispatch(updateProps(curr));
						}),
						pipeIf(
							unknownPlayedPileTaken || pileTaken,
							takePileAnimation(pileCardsToAnimate)
						)
					);
				})
			)
			.subscribe((properties) => {});

		return () => subscription.unsubscribe();

		/*
			React guarantees that dispatch function identity is stable and won’t change on re-renders.
			This is why it’s safe to omit from the useEffect or useCallback dependency list.
			https://reactjs.org/docs/hooks-reference.html
		*/
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return <>{props.children}</>;
};

export default AnimationWrapper;
