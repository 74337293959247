import {
	Button,
	Container,
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from '@material-ui/core';
import React from 'react';
import LobbyCreateMatchForm from './LobbyCreateMatchForm';
import LobbyMatchInstance from './LobbyMatchInstance';

const renderMatches = (
	matches,
	playerName,
	_joinMatch,
	_leaveMatch,
	_startMatch,
	errorMsg
) => {
	const matchesMap = new Map(matches.map((match) => [match.matchID, match]));
	const uniqueMatches = [...matchesMap.values()];
	matchesMap.clear();

	return uniqueMatches.map((match) => {
		const { matchID, gameName, players } = match;
		return (
			<LobbyMatchInstance
				key={'instance-' + matchID}
				match={{ matchID, gameName, players: Object.values(players) }}
				playerName={playerName}
				onClickJoin={_joinMatch}
				onClickLeave={_leaveMatch}
				onClickPlay={_startMatch}
				errorMsg={errorMsg}
			/>
		);
	});
};

const LobbyListOfGames = ({
	playerName,
	gameComponents,
	matches,
	handleCreateMatch,
	handleJoinMatch,
	handleLeaveMatch,
	handleStartMatch,
	handleExitLobby,
	errorMsg
}) => {
	return (
		<Container component="main" maxWidth="md" style={{ padding: '2em' }}>
			<Grid container direction="column">
				<Grid item>
					<Typography component="h1" variant="h4">
						Welcome, {playerName}
					</Typography>
				</Grid>
				<Grid item>
					<Typography component="h2" variant="h5">
						Create a match:
					</Typography>
				</Grid>
				<Grid item>
					<LobbyCreateMatchForm
						games={gameComponents}
						createMatch={handleCreateMatch}
					/>
				</Grid>
				<Grid item>
					<TableContainer component={Paper} style={{ marginTop: 20 }}>
						<Table aria-label="list of games">
							<TableHead>
								<TableRow>
									<TableCell>Game</TableCell>
									<TableCell>Status</TableCell>
									<TableCell>Players</TableCell>
									<TableCell align="right"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{renderMatches(
									matches,
									playerName,
									handleJoinMatch,
									handleLeaveMatch,
									handleStartMatch,
									errorMsg
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Button
					fullWidth
					variant="contained"
					color="secondary"
					style={{ marginTop: '1em' }}
					onClick={handleExitLobby}
				>
					Exit lobby
				</Button>
			</Grid>
		</Container>
	);
};

export default LobbyListOfGames;
