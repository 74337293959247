import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select
} from '@material-ui/core';
import React, { useState } from 'react';

const LobbyCreateMatchForm = (props) => {
	const [selectedGame, setSelectedGame] = useState(0);
	const [numPlayers, setNumPlayers] = useState(
		props.games[0].game.minPlayers || 2
	);

	/* fix min and max number of players */
	for (let game of props.games) {
		let matchDetails = game.game;
		if (!matchDetails.minPlayers) {
			matchDetails.minPlayers = 1;
		}
		if (!matchDetails.maxPlayers) {
			matchDetails.maxPlayers = 4;
		}
		console.assert(matchDetails.maxPlayers >= matchDetails.minPlayers);
	}

	const _createGameNameOption = (game, idx) => {
		return (
			<MenuItem key={'name-option-' + idx} value={idx}>
				{game.game.name}
			</MenuItem>
		);
	};

	const _createNumPlayersOption = (idx) => {
		return (
			<MenuItem key={'num-option-' + idx} value={idx}>
				{idx}
			</MenuItem>
		);
	};

	const _createNumPlayersRange = (game) => {
		return [...new Array(game.maxPlayers + 1).keys()].slice(game.minPlayers);
	};

	const onChangeNumPlayers = (event) => {
		setNumPlayers(Number.parseInt(event.target.value));
	};

	const onChangeSelectedGame = (event) => {
		let idx = Number.parseInt(event.target.value);
		setSelectedGame(idx);
		setNumPlayers(props.games[idx].game.minPlayers);
	};

	const onClickCreate = () => {
		props.createMatch(props.games[selectedGame].game.name, numPlayers);
	};
	return (
		<Grid container alignItems="flex-end" spacing={2}>
			<Grid item>
				<FormControl>
					<InputLabel id="game-name-label">Game</InputLabel>
					<Select
						labelId="game-name-label"
						value={selectedGame}
						onChange={(evt) => onChangeSelectedGame(evt)}
					>
						{props.games.map(_createGameNameOption)}
					</Select>
				</FormControl>
			</Grid>
			<Grid item>
				<FormControl>
					<InputLabel id="players-num-label">Players</InputLabel>
					<Select
						labelId="players-num-label"
						value={numPlayers}
						onChange={onChangeNumPlayers}
					>
						{_createNumPlayersRange(props.games[selectedGame].game).map(
							_createNumPlayersOption
						)}
					</Select>
				</FormControl>
			</Grid>
			<Grid item>
				<Button variant="contained" color="primary" onClick={onClickCreate}>
					Create
				</Button>
			</Grid>
		</Grid>
	);
};

export default LobbyCreateMatchForm;
