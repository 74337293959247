import React, { useState, useEffect } from 'react';
import {
	Button,
	Grid,
	makeStyles,
	Paper,
	TextField,
	Typography
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles({
	root: ({ height }) => ({
		// scale height according to window and offset height to prevent scrollbar flickering
		minHeight: Math.min(650, height - 50)
	}),
	marginTop: {
		marginTop: 20
	},
	paperPadding: {
		marginTop: 'auto',
		padding: 40
	}
});

const getHeight = () =>
	window.innerHeight ||
	document.documentElement.clientHeight ||
	document.body.clientHeight;

const useCurrentHeight = () => {
	// save current window width in the state object
	let [height, setHeight] = useState(getHeight());

	// in this case useEffect will execute only once because
	// it does not have any dependencies.
	useEffect(() => {
		// timeoutId for debounce mechanism
		let timeoutId = null;
		const resizeListener = () => {
			// prevent execution of previous setTimeout
			clearTimeout(timeoutId);
			// change width from the state object after 0 milliseconds
			timeoutId = setTimeout(() => setHeight(getHeight()), 0);
		};
		// set resize listener
		window.addEventListener('resize', resizeListener);

		// clean up function
		return () => {
			// remove resize listener
			window.removeEventListener('resize', resizeListener);
		};
	}, []);

	return height;
};

const LobbyLoginForm = (props) => {
	const [playerName, setPlayerName] = useState('');
	const [nameTextFieldTouched, setNameTextFieldTouched] = useState(false);
	const height = useCurrentHeight();

	const classes = useStyles({ height });

	const onClickEnter = (handleOnEnter) => () => {
		if (!playerName) {
			setNameTextFieldTouched(true);
		} else {
			handleOnEnter(playerName);
		}
	};

	const onKeyPress = (handleOnEnter) => (event) => {
		if (event.key === 'Enter') {
			onClickEnter(handleOnEnter)();
		}
	};

	const onChangePlayerName = (event) => {
		if (!nameTextFieldTouched) {
			setNameTextFieldTouched(true);
		}

		const name = event.target.value.trim();
		setPlayerName(name);
	};

	return (
		<Grid
			container
			direction="column"
			justify="center"
			alignItems="center"
			className={classes.root}
		>
			<Grid item>
				<Paper className={clsx(classes.paperPadding)}>
					<Typography component="h1" variant="h5">
						Choose Name
					</Typography>
					<div className={classes.marginTop}>
						<TextField
							variant="outlined"
							value={playerName}
							error={nameTextFieldTouched && !playerName}
							fullWidth
							required
							label="Player Name"
							autoFocus
							onChange={onChangePlayerName}
							onKeyUp={onKeyPress(props.handleOnEnter)}
						/>
						<Button
							className={classes.marginTop}
							fullWidth
							variant="contained"
							color="primary"
							onClick={onClickEnter(props.handleOnEnter)}
						>
							Enter
						</Button>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default LobbyLoginForm;
