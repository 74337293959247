import clsx from 'clsx';
import { useContext } from 'react';
import { CARD_SIZE } from '../constants/constants';
import { StateContext } from '../store/store';

const CardImage = ({
	cardId,
	cardImage,
	className = {},
	style = {},
	onClick = () => {}
}) => {
	const [{ selectedCards }] = useContext(StateContext);
	const highlighted = selectedCards?.includes(cardId);
	return (
		<img
			className={clsx(
				className,
				'cursor-pointer rounded-2',
				highlighted && 'shadow-highlighted -mt-5',
				'max-w-unset'
			)}
			style={{
				...style,
				height: CARD_SIZE.height,
				backfaceVisibility: 'hidden'
			}}
			src={cardImage.src}
			alt={cardImage.alt}
			onClick={() => onClick()}
		/>
	);
};

export default CardImage;
