import React, { createContext, useReducer } from 'react';
import {
	ANIMATE_PLAY_CARD_END,
	ANIMATE_PLAY_CARD_START,
	ANIMATE_TAKE_PILE_END,
	ANIMATE_TAKE_PILE_START,
	CARD_CLICKED,
	SELECT_CARDS,
	UNSELECT_CARDS,
	UPDATE_PROPS
} from './actions';

export const StateContext = createContext();

//export const useStoreState = () => useContext(StateContext);

export const initialState = (props) => ({
	...props,
	selectedCards: [],
	cardsOrigin: '',
	animation: false,
	animatePlayCard: false,
	cardsToAnimate: [],
	animateTakePile: false
});

const useReducerWithMiddleware = (
	reducer,
	initialState,
	middlewareFn = () => {}
) => {
	const [state, dispatch] = useReducer(reducer, initialState);

	const dispatchWithMiddleware = (action) => {
		middlewareFn(action);
		dispatch(action);
	};

	return [state, dispatchWithMiddleware];
};

const handleCardClick = (id, cardsOrigin, state) => {
	// can select and play multiple cards of the same value
	//console.log(this.props.ctx);

	//console.log(state);
	const selectedCards = state.selectedCards;

	if (state.ctx.phase === 'swapping') {
		return selectedCards.length > 0
			? { selectedCards: [] }
			: { selectedCards: [id] };
	} else {
		if (selectedCards.length > 0) {
			const isSameCard = selectedCards.some(
				(selectedCard) => selectedCard === id
			);
			// except unknown cards
			const sameValueCard =
				id.charAt(0) === selectedCards[0].charAt(0) &&
				cardsOrigin !== 'unknownCards' &&
				state.cardsOrigin !== 'unknownCards';

			if (isSameCard) {
				return { selectedCards: [], cardsOrigin };
			} else if (sameValueCard) {
				return {
					selectedCards: [...selectedCards, id],
					cardsOrigin
				};
			} else {
				return { selectedCards: [id], cardsOrigin };
			}
		} else {
			return { selectedCards: [id], cardsOrigin };
		}
		//console.log(e.type, id);
	}
};

export const StateProvider = ({
	reducer,
	initialState,
	children,
	middlewareFn
}) => (
	<StateContext.Provider
		value={useReducerWithMiddleware(reducer, initialState, middlewareFn)}
	>
		{children}
	</StateContext.Provider>
);

export const reducer = (state, action) => {
	switch (action.type) {
		case CARD_CLICKED:
			return {
				...state,
				...handleCardClick(action.cardId, action.cardsOrigin, state)
			};
		case SELECT_CARDS:
			return {
				...state,
				selectedCards: action.cards,
				cardsOrigin: action.cardsOrigin
			};
		case UNSELECT_CARDS:
			return {
				...state,
				selectedCards: [],
				cardsOrigin: ''
			};
		case ANIMATE_PLAY_CARD_START:
			return {
				...state,
				animatePlayCard: true,
				cardsToAnimate: action.cardsToAnimate
			};
		case ANIMATE_PLAY_CARD_END:
			return {
				...state,
				animatePlayCard: false,
				cardsToAnimate: []
			};
		case ANIMATE_TAKE_PILE_START:
			return {
				...state,
				animateTakePile: true,
				cardsToAnimate: action.cardsToAnimate
			};
		case ANIMATE_TAKE_PILE_END:
			return {
				...state,
				animateTakePile: false,
				cardsToAnimate: []
			};
		case UPDATE_PROPS:
			return {
				...state,
				...action.props
			};
		default:
			return { ...state };
	}
};
